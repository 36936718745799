.content {
  position: absolute;
  display: none;
  animation: fade_in_dropdown;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  margin-top: 16px;
  z-index: 10;
  left: 0;
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  border: 1px solid var(--light-blue-30);

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 16px;
    top: -16px;
    left: 0;
  }
}

.open {
  display: block;
}

@keyframes fade_in_dropdown {
  from {
    transform: translate3d(0, -3%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;

    transform: translate3d(0, 0%, 0);
  }
}
