@import "styles/mixins/breakpoints";

.wrapper {
  border-radius: 80px;
  background-size: cover;
  background-position-y: center;
  --headerText: white;
  --bodyText: white;

  @include media-breakpoint-down(l) {
    border-radius: 60px;
  }

  @include media-breakpoint-down(m) {
    border-radius: 56px;
  }

  @include media-breakpoint-down(s) {
    border-radius: 40px;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 28px;
  }

  @include media-breakpoint-down(xss) {
    border-radius: 24px;
  }
}

.contentContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;
}

.h2 {
  display: inline-block;
  color: var(--headerText);

  @include responsive-margin-top(
    $xxxl: 160px,
    $xxl: 160px,
    $xl: 120px,
    $l: 100px,
    $m: 80px,
    $s: 80px,
    $xs: 80px,
    $xss: 56px
  );

  @include responsive-margin-bottom(
    $xxxl: 40px,
    $xxl: 36px,
    $xl: 36px,
    $l: 28px,
    $m: 20px,
    $s: 20px,
    $xs: 16px,
    $xss: 12px
  );
}

.text {
  display: inline-block;
  max-width: 600px;
  color: var(--bodyText);
}

.button {
  display: inline-flex;

  @include responsive-margin-top(
    $xxxl: 36px,
    $xxl: 36px,
    $xl: 36px,
    $l: 28px,
    $m: 24px,
    $s: 24px,
    $xs: 20px,
    $xss: 16px
  );

  @include responsive-margin-bottom(
    $xxxl: 160px,
    $xxl: 160px,
    $xl: 120px,
    $l: 100px,
    $m: 80px,
    $s: 80px,
    $xs: 80px,
    $xss: 56px
  );
}
