@import "styles/mixins/breakpoints";

.wrapper {
  padding-top: 0;
  padding-bottom: 0;
}

.h2 {
  text-align: center;
  color: var(--light-purple-20);

  @include media-breakpoint-down(s) {
    text-align: left;
  }

  @include media-breakpoint-up(2xl) {
    text-align: center;
  }

  @include media-breakpoint-up(3xl) {
    text-align: left;
  }

  @include responsive-margin-top(
    $xxxl: 120px,
    $xxl: 100px,
    $xl: 80px,
    $l: 64px,
    $m: 64px,
    $s: 56px,
    $xs: 44px,
    $xss: 36px
  );

  @include responsive-padding-bottom(
    $xxxl: 120px,
    $xxl: 100px,
    $xl: 80px,
    $l: 64px,
    $m: 56px,
    $s: 56px,
    $xs: 40px,
    $xss: 32px
  );

  margin-left: auto;
  margin-right: auto;
}

.slideText {
  color: inherit;
}

.codeSlider {
  @include responsive-margin-bottom(
    $xxxl: 160px,
    $xxl: 140px,
    $xl: 120px,
    $l: 100px,
    $m: 88px,
    $s: 72px,
    $xs: 64px,
    $xss: 48px
  );
}

.textWrapper {
  color: var(--light-purple-20);
  grid-row-gap: 0;

  @include responsive-padding-bottom(
    $xxxl: 160px,
    $xxl: 140px,
    $xl: 120px,
    $l: 100px,
    $m: 88px,
    $s: 88px,
    $xs: 88px,
    $xss: 72px
  );
}

.body3 {
  display: block;

  @include responsive-margin-bottom(
    $xxxl: 36px,
    $xxl: 36px,
    $xl: 36px,
    $l: 28px,
    $m: 20px,
    $s: 20px,
    $xs: 16px,
    $xss: 16px
  );
}

.buttonWrapper {
  @include responsive-margin-bottom(
    $xxxl: 0,
    $xxl: 0,
    $xl: 0,
    $l: 0,
    $m: 0,
    $s: 48px,
    $xs: 32px,
    $xss: 28px
  );
}

.popoverTextLight {
  color: var(--light-purple-10);
}

.popoverTextDark {
  color: var(--light-purple-80);
}

.docsButton {
  span {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }
}
