@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  :global {
    .slick-slide {
      padding: 0 12px;
    }

    .slick-current {
      padding: 0;
    }
  }
}

.slideWrapper {
  border-radius: 30px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 650px;
  position: relative;

  &:after {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    height: 32px;
    width: 100%;
  }

  &purple:after {
    background: var(--dark-purple-80);
  }

  &lilac:after {
    background: var(--dark-lilac-40);
  }

  &blue:after {
    background: var(--dark-blue-80);
  }

  @include media-breakpoint-down(m) {
    display: flex !important;
    flex-direction: column;
    height: auto;

    &:after {
      content: "";
      display: block;
    }
  }
}

.contentWrapper {
  padding: 38px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  img {
    user-select: none;
    -webkit-user-drag: none;
  }

  @include media-breakpoint-down(m) {
    height: 360px;
  }

  @include media-breakpoint-down(s) {
    height: 280px;
  }

  &purple {
    background: var(--dark-purple-60);
    color: var(--light-purple-40);
  }

  &lilac {
    background: var(--dark-lilac-60);
    color: var(--light-lilac-10);
  }

  &blue {
    background: var(--dark-blue-60);
    color: var(--light-blue-40);
  }
}

.codeWrapper {
  padding: 34px 0;
  height: 650px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-down(m) {
    height: 260px;
  }

  @include media-breakpoint-down(s) {
    height: 220px;
  }

  &purple {
    background: var(--dark-purple-80);
  }

  &lilac {
    background: var(--dark-lilac-40);
  }

  &blue {
    background: var(--dark-blue-80);
  }
}

.sliderDot {
  cursor: pointer;
  transition: 0.2s;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.01em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 243px;
  position: relative;
  min-width: 155px;

  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(50% - 22px);
    height: 1px;
    right: 0;
    top: 18px;
  }

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }

  &:first-child:after {
    display: none;
  }

  &:last-child:before {
    display: none;
  }

  &Purple {
    color: var(--dark-purple-60);

    &:after,
    &:before {
      background: var(--dark-purple-80);
    }
  }

  &Lilac {
    color: var(--dark-lilac-50);

    &:after,
    &:before {
      background: var(--dark-lilac-50);
    }
  }

  &Blue {
    color: var(--dark-blue-60);

    &:after,
    &:before {
      background: var(--dark-blue-80);
    }
  }
}

.dotNumber {
  width: 36px;
  height: 36px;
  border-radius: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  position: relative;
  margin-bottom: 16px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 44px;
    height: 44px;
    border-radius: 44px;
  }

  &Purple {
    &:after {
      border: 1px solid var(--dark-purple-80);
    }
  }

  &Lilac {
    &:after {
      border: 1px solid var(--dark-lilac-50);
    }
  }

  &Blue {
    &:after {
      border: 1px solid var(--dark-blue-80);
    }
  }
}

.currentDot {
  cursor: default;
  pointer-events: none;

  &Purple {
    color: var(--light-purple-100);

    .dotNumber {
      background: var(--light-purple-100);
      color: var(--dark-purple-80);

      &:after {
        border-color: var(--light-purple-100);
      }
    }
  }

  &Lilac {
    color: var(--light-lilac-100);

    .dotNumber {
      background: var(--light-lilac-100);
      color: var(--dark-lilac-80);

      &:after {
        border-color: var(--light-lilac-100);
      }
    }
  }

  &Blue {
    color: var(--light-blue-100);

    .dotNumber {
      background: var(--light-blue-100);
      color: var(--dark-blue-80);

      &:after {
        border-color: var(--light-blue-100);
      }
    }
  }
}

.dotsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-down(m) {
    overflow-x: scroll;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 4px;
  }
}
