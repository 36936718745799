@import "styles/mixins/breakpoints";

.wrapper {
  position: relative;
  gap: 8px;

  @include responsive-padding-bottom(
    $xxxl: 140px,
    $xxl: 140px,
    $xl: 140px,
    $l: 66px,
    $m: 64px,
    $s: 64px,
    $xs: 56px,
    $xss: 36px
  );
}

.text {
  display: block;
  text-align: center;

  @include media-breakpoint-down(s) {
    text-align: left;
  }
}

.h3 {
  color: var(--light-blue-5);

  @include responsive-padding-top(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 100px,
    $l: 80px,
    $m: 80px,
    $s: 56px,
    $xs: 56px,
    $xss: 36px
  );
}

.body4 {
  display: block;
  color: var(--text-black-40);

  @include responsive-padding-top(
    $xxxl: 36px,
    $xxl: 36px,
    $xl: 36px,
    $l: 36px,
    $m: 36px,
    $s: 18px,
    $xs: 18px,
    $xss: 16px
  );

  @include responsive-padding-bottom(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 100px,
    $l: 64px,
    $m: 64px,
    $s: 56px,
    $xs: 56px,
    $xss: 36px
  );
}

.productCard {
  @include media-breakpoint-down(s) {
    justify-self: center;
  }
}
