@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  li {
    list-style: none;
  }

  @include media-breakpoint-down(m) {
    display: none;
  }
}
