@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  display: flex;
  gap: 4px;
  white-space: nowrap;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;
  user-select: none;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    .text {
      --color: white;

      &-purple {
        --background: var(--light-purple-100);
      }

      &-lilac {
        --background: var(--light-lilac-100);
      }

      &-marine {
        --background: var(--light-marine-100);
      }

      &-blue {
        --background: var(--light-blue-100);
      }

      &-peach {
        --background: var(--light-peach-100);
      }
    }
  }

  &:active,
  &.current {
    .text,
    .bubble {
      --color: white;

      &-purple {
        --background: var(--light-purple-80);
      }

      &-lilac {
        --background: var(--light-lilac-100); // пока сотка, не стал заниматься самодеятельностью, в руте 80 цвета нет
      }

      &-marine {
        --background: var(--light-marine-80);
      }

      &-blue {
        --background: var(--light-blue-80);
      }

      &-peach {
        --background: var(--light-peach-80);
      }
    }
  }

  &.current {
    pointer-events: none;
  }

  @include media-breakpoint-down(m) {
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 20px;
  }
}

.bubble {
  --background: var(--light-purple-100);

  background-color: var(--background);
  padding: 10px 12px;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: #ffffff;
  border-radius: 100px;
  transition: 0.2s;

  &-purple {
    --background: var(--light-purple-100);
  }

  &-lilac {
    --background: var(--light-lilac-100);
  }

  &-marine {
    --background: var(--light-marine-100);
  }

  &-blue {
    --background: var(--light-blue-100);
  }

  &-peach {
    --background: var(--light-peach-100);
  }

  @include media-breakpoint-down(m) {
    font-size: 14px;
    line-height: 20px;
  }
}

.text {
  --background: var(--light-purple-20);
  --color: var(--light-purple-100);
  color: var(--color);
  background: var(--background);
  padding: 10px 12px;
  border-radius: 100px;
  transition: 0.2s;

  &-purple {
    --background: var(--light-purple-20);
    --color: var(--light-purple-100);
  }

  &-lilac {
    --background: var(--light-lilac-20);
    --color: var(--light-lilac-100);
  }

  &-marine {
    --background: var(--light-marine-20);
    --color: var(--light-marine-100);
  }

  &-blue {
    --background: var(--light-blue-10);
    --color: var(--light-blue-100);
  }

  &-peach {
    --background: var(--light-peach-20);
    --color: var(--light-peach-100);
  }
}
