@import "styles/mixins/breakpoints";

.wrapper {
  @include responsive-padding-top(
    $xxxl: 160px,
    $xxl: 160px,
    $xl: 80px,
    $l: 80px,
    $m: 72px,
    $s: 72px,
    $xs: 36px,
    $xss: 36px
  );

  @include responsive-padding-bottom(
    $xxxl: 160px,
    $xxl: 160px,
    $xl: 80px,
    $l: 80px,
    $m: 88px,
    $s: 88px,
    $xs: 36px,
    $xss: 36px
  );
}

.videoWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.h2 {
  position: relative;
  color: var(--text-white);

  @include media-breakpoint-down(s) {
    padding: 0 16px;
  }

  @include responsive-margin-top(
    $xxxl: 246px,
    $xxl: 246px,
    $xl: 246px,
    $l: 210px,
    $m: 210px,
    $s: 160px,
    $xs: 76px,
    $xss: 96px
  );

  @include responsive-margin-bottom(
    $xxxl: 310px,
    $xxl: 310px,
    $xl: 310px,
    $l: 270px,
    $m: 270px,
    $s: 216px,
    $xs: 160px,
    $xss: 160px
  );
}

.body {
  display: block;
  width: 626px;
  text-align: center;
  margin: 0 auto;
  color: var(--text-black-40);

  @include media-breakpoint-down(s) {
    width: 100%;
    text-align: left;
    padding: 0 16px;
  }
}

.video {
  position: absolute;
  width: 666px;
  height: 666px;
  display: block;
  max-width: 700px;
  opacity: 0.8;
  border-radius: 100%;

  @include media-breakpoint-down(xl) {
    width: 536px;
    height: 536px;
  }

  @include media-breakpoint-down(m) {
    width: 417px;
    height: 417px;
  }

  @include media-breakpoint-down(s) {
    width: 264px;
    height: 252px;
  }
}

.numbersWrapper {
  display: flex;
  justify-content: center;
  column-gap: 228px;

  @include responsive-padding-top(
    $xxxl: 88px,
    $xxl: 88px,
    $xl: 80px,
    $l: 80px,
    $m: 80px,
    $s: 88px,
    $xs: 36px,
    $xss: 36px
  );

  @include responsive-padding-bottom(
    $xxxl: 80px,
    $xxl: 80px,
    $xl: 100px,
    $l: 100px,
    $m: 100px,
    $s: 100px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(xl) {
    column-gap: 86px;
  }

  @include media-breakpoint-down(m) {
    justify-content: left;
    flex-wrap: wrap;
    column-gap: 78px;
    row-gap: 60px;
  }

  @include media-breakpoint-down(s) {
    row-gap: 36px;
  }
}

.titleExpert {
  display: block;
  text-align: center;
  color: var(--text-white);

  @include responsive-padding-bottom(
    $xxxl: 160px,
    $xxl: 160px,
    $xl: 80px,
    $l: 80px,
    $m: 88px,
    $s: 88px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    text-align: left;
  }
}
