@import "styles/mixins/breakpoints";

.wrapper {
  padding: 0 20px 20px 40px;
  white-space: pre;
  font-family: SourceCodePro, monospace;
  font-size: 16px;
  line-height: 21px;
  position: relative;
  color: var(--text-black-40);

  :global(.hljs-tag) {
    //символы </>
    color: var(--text-black-40);
  }

  :global(.hljs-name),
  :global(.hljs-attr) {
    //тег
    color: var(--light-purple-100);
  }

  :global(.hljs-string),
  :global(.hljs-number) {
    //строка
    color: var(--adjective-color-orange);
  }

  @include media-breakpoint-down(s) {
    font-size: 11px;
    line-height: 13px;
  }
}

.target.active {
  :global(.fakeCursor):after {
    content: " ";
    background-color: var(--text-black-80);
    height: 1em;
    width: 2px;
    position: absolute;
    top: 5px;
    margin-left: 3px;
    display: inline-block;
  }
}

.existingLine {
  background-color: gray;
}

.editorContainer {
  position: relative;
  min-width: 600px;
}

.dark .editorContainer {
  background-color: #303030;
}

.animatedPreview {
  width: 100%;
}

.description {
  font-family: SourceCodePro, monospace;
  color: var(--text-black-40);
  font-size: 14px;
  padding-left: 20px;
  margin-bottom: 8px;
  margin-top: 16px;
}

.linesNumberWrapper {
  position: absolute;
}

.wrap {
  white-space: break-spaces;
}
