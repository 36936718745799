@import "styles/mixins/breakpoints";

.wrapper {
  display: none;

  :global {
    body {
      overflow: hidden !important;
    }
  }

  @include media-breakpoint-down(m) {
    display: block;
  }
}

.itemsWrapper {
  background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  animation: fade_in_menu;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  overflow-y: scroll;
  list-style-type: none;
  padding: 0;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--text-black-10);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--text-black-30);
  }

  svg[data-icon="arrow45"] {
    width: 28px;
    height: 28px;
  }
}

@keyframes fade_in_menu {
  from {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}

.header {
  margin: 24px 20px;
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xss) {
    height: 44px;
  }

  @include media-breakpoint-up(xs) {
    height: 48px;
  }
}

.content {
  padding: 20px;
  display: grid;
  gap: 48px;
  grid-template-areas:
    "Header_products Header_company "
    "Header_products Header_engine "
    "Header_products payments ";

  @include media-breakpoint-down(s) {
    display: flex;
    flex-direction: column;
  }
}

.logo {
  @include media-breakpoint-up(xss) {
    width: 36px;
    height: 36px;
  }

  @include media-breakpoint-up(xs) {
    width: 40px;
    height: 40px;
  }

  @include media-breakpoint-up(3xl) {
    width: 52px;
    height: 52px;
  }
}

.link {
  color: inherit;
  text-decoration: inherit;
}
