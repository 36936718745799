@import "styles/variables";
@import "styles/mixins/breakpoints";

.wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  color: white;
  margin: 24px auto 0;
  z-index: 2;

  @include media-breakpoint-up(xss) {
    height: 44px;
  }

  @include media-breakpoint-up(xs) {
    height: 48px;
  }

  :global(.fixedMenu) & {
    position: relative;
    margin: 0 0 0 8px;
    justify-content: flex-end;
    padding: 0;

    @include media-breakpoint-down(m) {
      height: 48px;
      order: 3;
      padding: 0;
      width: auto;
    }
  }
}

.mainLogo {
  @include media-breakpoint-up(xss) {
    width: 36px;
    height: 36px;
  }

  @include media-breakpoint-up(xs) {
    width: 40px;
    height: 40px;
  }

  @include media-breakpoint-up(3xl) {
    width: 52px;
    height: 52px;
  }

  :global(.fixedMenu) & {
    display: none;
  }
}

.link {
  color: inherit;
  text-decoration: inherit;
}
