@import "styles/mixins/breakpoints";

.wrapper {
  display: flex;
  max-width: 260px;
  gap: 8px;
  color: var(--text-white);

  @include media-breakpoint-down(xl) {
    max-width: 236px;
  }

  @include media-breakpoint-down(m) {
    max-width: 100%;
    width: calc(50% - 88px);
  }

  @include media-breakpoint-down(s) {
    width: 100%;
  }
}

.separator {
  display: flex;
  align-items: center;
  height: 120px;
  min-width: 20px;

  @include media-breakpoint-down(3xl) {
    height: 100px
  }

  @include media-breakpoint-down(2xl) {
    height: 88px
  }

  @include media-breakpoint-down(xl) {
    height: 72px;
    min-width: 12px;
  }

  @include media-breakpoint-down(l) {
    height: 62px
  }

  @include media-breakpoint-down(m) {
    height: 59px
  }

  @include media-breakpoint-down(s) {
    height: 42px
  }

  @include media-breakpoint-down(xs) {
    height: 29px
  }
}

.wrapperNumbers {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-down(m) {
    gap: 6px;
  }

  @include media-breakpoint-down(s) {
    gap: 8px;
  }
}

.number {
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.title3 {
  color: var(--text-black-80);
}