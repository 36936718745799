@import "styles/mixins/breakpoints";

.wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  color: white;
  transition: 0.2s;
  cursor: default;
  user-select: none;
  list-style-type: none;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.01em;
  padding: 18px 32px;

  @include media-breakpoint-down(l) {
    padding: 18px 24px;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  :global(.open) &,
  &:hover {
    background: rgba(255, 255, 255, 0.15);

    .aim {
      display: none;
    }

    .halfAim {
      display: inline;
    }
  }

  :global(.fixedMenu) & {
    color: var(--text-black-100);
  }
}

.linkWrapper {
  padding: 0;

  &:active {
    opacity: 0.8;
  }
}

.aim {
  display: inline;
}

.halfAim {
  display: none;
}

.link {
  cursor: pointer;
  color: white;
  white-space: nowrap;
  padding: 18px 32px;

  @include media-breakpoint-down(l) {
    padding: 18px 24px;
  }

  :global(.fixedMenu) & {
    color: var(--text-black-100);
  }
}
