@import "styles/mixins/breakpoints";

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: var(--text-white);

  @include responsive-padding-top(
    $xxxl: 140px,
    $xxl: 140px,
    $xl: 72px,
    $l: 72px,
    $m: 56px,
    $s: 56px,
    $xs: 56px,
    $xss: 56px
  );

  @include responsive-padding-bottom(
    $xxxl: 140px,
    $xxl: 140px,
    $xl: 80px,
    $l: 80px,
    $m: 72px,
    $s: 72px,
    $xs: 48px,
    $xss: 48px
  );
}

.head {
  color: var(--dark-blue-90);
}

.description {
  color: var(--dark-blue-90);

  @include responsive-padding-top(
    $xxxl: 0,
    $xxl: 0,
    $xl: 0,
    $l: 22px,
    $m: 22px,
    $s: 26px,
    $xs: 26px,
    $xss: 26px
  );
}

.slider {
  @include responsive-padding-top(
    $xxxl: 230px,
    $xxl: 230px,
    $xl: 120px,
    $l: 120px,
    $m: 80px,
    $s: 80px,
    $xs: 48px,
    $xss: 48px
  );
}

.tickerImages {
  height: 134px;

  @include media-breakpoint-down(xl) {
    height: 99px;
  }

  @include media-breakpoint-down(m) {
    height: 90px;
  }

  @include media-breakpoint-down(s) {
    height: 48px;
  }
}

.mobileHidden {
  @include media-breakpoint-down(s) {
    display: none;
  }
}
