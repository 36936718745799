@import "styles/variables";
@import "styles/mixins/breakpoints";
.label {
  color: var(--text-black-40);
  margin-bottom: 12px;
  margin-top: 20px;
  display: block;
  font-size: 14px;
  line-height: 21px;

  &:first-child {
    margin-top: 0;
  }

  @include media-breakpoint-down(m) {
    margin-bottom: 16px;

    //оч костыльно
    [data-group="Header_products"] &:nth-child(6) {
      margin-top: 48px;
    }
  }
}
