@import "styles/mixins/breakpoints";

.wrapper {
  position: sticky;
  top: 24px;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid var(--light-blue-30);
  border-radius: 50px;
  max-width: 1168px;
  z-index: 20;
  padding: 8px 8px 8px 20px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 40px;
  display: none;
  animation: fade_in_menu;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease;

  &.open {
    display: flex;
  }

  @include media-breakpoint-down(l) {
    margin: 0 48px 40px;
  }

  @include media-breakpoint-down(m) {
    margin: 0 40px 40px;
  }

  @include media-breakpoint-down(s) {
    margin: 0 20px 40px;
  }

  @include media-breakpoint-down(xs) {
    margin: 0 16px 40px;
  }

  .mobileButton {
    display: none;
  
    @include media-breakpoint-down(m) {
      display: inline-flex;
      margin-left: auto;
    }
  }
}

.mainLogo {
  color: var(--light-blue-100);
  flex-shrink: 0;

  @include media-breakpoint-up(xss) {
    width: 36px;
    height: 36px;
  }

  @include media-breakpoint-up(xs) {
    width: 40px;
    height: 40px;
  }

  @include media-breakpoint-up(3xl) {
    width: 52px;
    height: 52px;
  }
}

.link {
  color: inherit;
  text-decoration: inherit;
}

@keyframes fade_in_menu {
  from {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }

  to {
    opacity: 1;

    transform: translate3d(0, 0%, 0);
  }
}
