@import "styles/mixins/breakpoints";

.wrapper {
  :global {
    .slick-slide {
      padding: 0 4px;
    }
  }
}

.arrows {
  @include responsive-margin-top(
    $xxxl: 40px,
    $xxl: 40px,
    $xl: 32px,
    $l: 32px,
    $m: 32px,
    $s: 32px,
    $xs: 32px,
    $xss: 32px
  );

  @include responsive-margin-bottom(
    $xxxl: 140px,
    $xxl: 140px,
    $xl: 80px,
    $l: 80px,
    $m: 80px,
    $s: 80px,
    $xs: 48px,
    $xss: 48px
  );
}