@import "styles/mixins/breakpoints";

.arrowsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @include responsive-margin-top(
    $xxxl: 48px,
    $xxl: 48px,
    $xl: 48px,
    $l: 28px,
    $m: 28px,
    $s: 28px,
    $xs: 28px,
    $xss: 28px
  );

  @include responsive-margin-bottom(
    $xxxl: 160px,
    $xxl: 140px,
    $xl: 120px,
    $l: 100px,
    $m: 88px,
    $s: 88px,
    $xs: 88px,
    $xss: 72px
  );
}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  transition: 0.2s;

  svg {
    width: 28px;
    height: 28px;
    transition: 0.2s;
  }

  &:hover {
    transform: scale(1.06);

    svg {
      transform: scale(0.7);
    }
  }

  &:active {
    opacity: 0.8;
  }

  @include media-breakpoint-down(3xl) {
    width: 56px;
    height: 56px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @include media-breakpoint-down(xl) {
    width: 48px;
    height: 48px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  @include media-breakpoint-down(xs) {
    width: 44px;
    height: 44px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.white {
  background-color:  var(--text-white);

  svg {
    color: var(--text-black-100);
  }
}

.darkBlue {
  background-color:  var(--dark-blue-60);

  svg {
    color: var(--text-white);
  }
}

.lightBlue {
  background-color:  var(--light-blue-10);

  svg {
    color: var(--dark-blue-80);
  }
}