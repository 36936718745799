/* stylelint-disable */

.root {
  padding: 0;

  :global {
    /* Slider */
    /* Dots */
    & .slick-slider {
      overflow: hidden;
      position: relative;

      display: block;
      box-sizing: border-box;

      user-select: none;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;

      & .slick-track {
        transform: translate3d(0, 0, 0);
      }

      & .slick-list {
        transform: translate3d(0, 0, 0);
      }
    }

    & .slick-list {
      position: relative;

      display: block;
      margin: 0;
      padding: 0;

      &:focus {
        outline: none;
      }
    }

    & .slick-list.dragging {
      cursor: hand;
    }

    & .slick-track {
      position: relative;
      top: 0;
      left: 0;

      display: block;
      margin-right: auto;
      margin-left: auto;

      &:before {
        display: table;

        content: "";
      }

      &:after {
        display: table;
        clear: both;

        content: "";
      }
    }

    & .slick-loading {
      & .slick-track {
        visibility: hidden;
      }

      & .slick-slide {
        visibility: hidden;
      }

      & .slick-list {
        background: #ffffff;
      }
    }

    & .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;

      & img {
        display: block;
      }

      [dir="rtl"] {
        & .slick-slide {
          float: right;
        }
      }
    }

    & .slick-slide.slick-loading {
      & img {
        display: none;
      }
    }

    & .slick-dots {
      & li {
        &.slick-active {
          & button {
            background: var(--text-primary);
          }
        }
        & button {
          &:before {
            display: none;
          }

          & &:hover {
            outline: none;
          }
        }
      }
    }

    & .slick-slide.dragging {
      & img {
        pointer-events: none;
      }
    }

    & .slick-initialized {
      & .slick-slide {
        display: block;
      }
    }

    & .slick-vertical {
      & .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
      }
    }

    & .slick-arrow.slick-hidden {
      display: none;
    }

    & .slick-dotted.slick-slider {
      margin-bottom: 36px;
    }

    & .slick-dots {
      position: absolute;
      bottom: -36px;

      display: block;
      width: 100%;
      margin: 0;
      padding: 0;

      text-align: center;

      list-style: none;

      & li {
        position: relative;

        display: inline-block;
        width: 24px;
        height: 36px;
        margin: 0;
        padding: 0;

        & button {
          display: block;
          width: 10px;
          height: 10px;
          margin: 13px 7px;
          padding: 0;
          border: 0;
          border-radius: 100%;

          font-size: 0;
          line-height: 0;

          background: #c4c4c6;
          outline: none;
          cursor: pointer;

          &:focus {
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.7);

            &:focus {
              outline: none;
            }
          }
        }

        & li.slick-active {
          & button {
            background: #9d9c9f;
            opacity: 1;
          }
        }
      }
    }

    & .slick-arrow {
      &.slick-prev {
        left: -35px;
      }

      &.slick-next {
        right: -35px;
      }
    }

    & .slick-arrow.slick-prev,
    & .slick-arrow.slick-next {
      display: flex;
      justify-content: center;
      width: 40px;
      height: 40px;

      &:before,
      &:after {
        display: none;
      }

      & svg {
        width: 100%;

        height: 100%;

        color: #98979a;
      }

      &:hover {
        & svg {
          color: var(--text-primary);
        }
      }
    }
  }
}
