.ticker {
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
}

.track {
  white-space: nowrap;
  will-change: auto;

  span {
    white-space: pre;
  }
}
