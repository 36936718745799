@import "styles/mixins/breakpoints";

.wrapper {
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.h2 {
  text-align: center;
  color: var(--light-blue-5);
}

.body {
  display: block;
  width: 495px;
  text-align: center;
  margin: 0 auto;
  padding-top: 36px;
  color: var(--text-white);

  @include media-breakpoint-down(s) {
    width: 100%;
  }
}

.video {
  position: absolute;
  width: 100%;
  opacity: 0.8;
  object-fit: cover;
  z-index: 0;
  height: 100vh;

  @include media-breakpoint-down(s) {
    min-height: 100%;
  }
}
