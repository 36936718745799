@import "styles/variables";
@import "styles/mixins/breakpoints";

.link {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;
  white-space: nowrap;
  color: var(--text-black-100);
  display: block;
  transition: 0.2s;

  &:hover,
  &.current {
    color: var(--light-blue-100);
  }

  &:active {
    color: var(--light-blue-80);
  }

  &.current {
    pointer-events: none;
  }

  @include media-breakpoint-down(m) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 16px;
  }
}

.liItem {
  display: block;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}
