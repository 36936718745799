@import "styles/mixins/breakpoints";

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 48px 44px 55px;
  width: 100%;
  height: 466px;
  border-radius: 30px;
  gap: 20px;
  margin: 0;
  hyphens: manual;

  background-size: cover;
  background-position-y: bottom;

  --title-text: white;
  --body-text: white;
  --white-label-bg: white;
  --white-label-text: white;

  @include media-breakpoint-down(xl) {
    padding: 32px 20px 40px;
    border-radius: 24px;
    height: 358px;
  }

  @include media-breakpoint-down(m) {
    flex-direction: column;
    border-radius: 20px;
    padding: 28px 24px 56px;
    height: 579px;
  }

  @include media-breakpoint-down(s) {
    padding: 20px 20px 52px;
    gap: 78px;
    max-width: 367px;
  }

  &:hover .linkLabel {
    transition: 0.2s;
    opacity: 0.8;
    transform: scale(1.03);
  }
}

.images {
  display: flex;
  align-self: center;
}

.img {
  &Desktop {
    @include media-breakpoint-down(xl) {
      height: 294px;
    }

    @include media-breakpoint-down(m) {
      display: none;
    }
  }

  &Mobile {
    display: none;

    @include media-breakpoint-down(m) {
      display: block;
      max-width: 240px;
    }
  }
}

.text {
  width: 413px;

  @include media-breakpoint-down(xl) {
    width: 309px;
  }

  @include media-breakpoint-down(l) {
    width: 216px;
  }

  @include media-breakpoint-down(m) {
    width: 100%;
  }
}

.bubbles {
  display: flex;
  gap: 8px;

  @include media-breakpoint-down(xl) {
    gap: 6px;
  }
}

.label {
  display: flex;
  flex-shrink: 0;
  align-items: center;

  padding: 10px 12px;
  border-radius: 100px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.01em;

  color: var(--text-white);
  background-color: rgba(255, 255, 255, 0.2);

  @include media-breakpoint-down(xl) {
    padding: 9px 11px;

    font-size: 14px;
    line-height: 18px;
  }

  @include media-breakpoint-down(s) {
    padding: 9px;

    line-height: 14px;
  }
}

.whiteLabel {
  color: var(--white-label-text);
  background: var(--white-label-bg);
}

.title {
  color: var(--title-text);

  @include responsive-padding-top(
    $xxxl: 32px,
    $xxl: 32px,
    $xl: 32px,
    $l: 20px,
    $m: 20px,
    $s: 20px,
    $xs: 16px,
    $xss: 16px
  );

  @include responsive-padding-bottom(
    $xxxl: 24px,
    $xxl: 24px,
    $xl: 24px,
    $l: 16px,
    $m: 16px,
    $s: 16px,
    $xs: 12px,
    $xss: 12px
  );
}

.body5 {
  display: block;
  color: var(--body-text);

  @include media-breakpoint-down(s) {
    max-width: 100%;
  }
}
