@import "styles/mixins/breakpoints";

.wrapper {
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, #0a153d 0%, #0f306a 100%);
  border-radius: 60px;
  @include responsive-padding-top(
    $xxxl: 85px,
    $xxl: 85px,
    $xl: 85px,
    $l: 60px,
    $m: 60px,
    $s: 60px,
    $xs: 0,
    $xss: 0
  );

  @include responsive-padding-bottom(
    $xxxl: 85px,
    $xxl: 85px,
    $xl: 85px,
    $l: 60px,
    $m: 60px,
    $s: 60px,
    $xs: 0,
    $xss: 0
  );

  @include media-breakpoint-down(s) {
    background: none;
  }
}

.info {
  @include media-breakpoint-down(m) {
    justify-self: center;
    text-align: center;
  }

  @include media-breakpoint-down(s) {
    justify-self: start;
    text-align: left;
  }
}

.h3 {
  white-space: normal;
  color: var(--light-blue-5);
}

.body4 {
  display: block;
  max-width: 380px;
  color: var(--text-black-40);

  @include media-breakpoint-down(m) {
    margin: 0 auto;
  }

  @include media-breakpoint-down(s) {
    max-width: 100%;
  }

  @include responsive-padding-top(
    $xxxl: 28px,
    $xxl: 28px,
    $xl: 28px,
    $l: 28px,
    $m: 28px,
    $s: 20px,
    $xs: 20px,
    $xss: 16px
  );

  @include responsive-padding-bottom(
    $xxxl: 50px,
    $xxl: 50px,
    $xl: 50px,
    $l: 36px,
    $m: 36px,
    $s: 36px,
    $xs: 36px,
    $xss: 32px
  );
}

.stores {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;

  @include media-breakpoint-down(xl) {
    gap: 24px;
  }

  @include media-breakpoint-down(m) {
    justify-content: center;
    padding-bottom: 78px;
    gap: 16px;
  }

  @include media-breakpoint-down(s) {
    padding-bottom: 36px;
    justify-content: left;
    gap: 24px;
  }
}

.store {
  width: 164px;
  height: 156px;
  cursor: pointer;
  margin: 0;

  @include media-breakpoint-down(xl) {
    width: 123px;
    height: 42px;
  }

  svg {
    &:hover {
      rect {
        transition: 0.2s;
        stroke: var(--light-blue-60);
      }
    }

    &:active {
      rect {
        transition: 0.2s;
        stroke: var(--light-blue-100);
      }
    }
  }
}

.product {
  @include media-breakpoint-down(m) {
    grid-column-start: 3;
    justify-self: center;
  }

  @include media-breakpoint-down(s) {
    grid-column-start: 1;
  }
}
