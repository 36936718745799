@import "styles/mixins/breakpoints";

.wrapper {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  width: 566px;
  height: 568px;
  padding: 35px 40px 50px;
  border-radius: 30px;
  background-color: var(--light-blue-10);

  @include media-breakpoint-down(xl) {
    width: 392px;
    height: 443px;
    padding: 40px 30px;
  }

  @include media-breakpoint-down(m) {
    width: 356px;
    height: 386px;
    padding: 26px;
  }

  @include media-breakpoint-down(s) {
    width: 272px;
    height: 440px;
    padding: 24px 20px;
  }

  &Img {
    display: flex;
    gap: 16px;
    align-items: flex-start;
  }

  &Text {
    display: flex;
    flex-direction: column;
  }
}

.title {
  text-align: left;
  color: var(--text-black-100);
}

.img {
  width: 60px;
  object-fit: cover;

  @include media-breakpoint-down(s) {
    width: 40px;
  }
}

.author {
  color: var(--text-black-100);
  margin-bottom: 8px;
}

.role {
  color: var(--text-black-60);
}
