@import "styles/mixins/breakpoints";

.wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: var(--text-white);
  border-radius: 80px 80px 0 0;

  @include responsive-padding-top(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 72px,
    $l: 72px,
    $m: 72px,
    $s: 72px,
    $xs: 36px,
    $xss: 36px
  );

  @include responsive-padding-bottom(
    $xxxl: 140px,
    $xxl: 140px,
    $xl: 100px,
    $l: 100px,
    $m: 72px,
    $s: 72px,
    $xs: 48px,
    $xss: 48px
  );

  @include media-breakpoint-only(xl) {
    border-radius: 72px 72px 0 0;
  }

  @include media-breakpoint-only(l) {
    border-radius: 60px 60px 0 0;
  }

  @include media-breakpoint-only(m) {
    border-radius: 56px 56px 0 0;
  }

  @include media-breakpoint-only(s) {
    border-radius: 40px 40px 0 0;
  }

  @include media-breakpoint-only(xs) {
    border-radius: 28px 28px 0 0;
  }

  @include media-breakpoint-down(xs) {
    border-radius: 24px 24px 0 0;
  }
}

.head {
  text-align: center;
  color: var(--dark-blue-90);

  @include responsive-padding-bottom(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 80px,
    $l: 80px,
    $m: 80px,
    $s: 80px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(s) {
    text-align: left;
  }
}

.developImage {
  grid-row-start: 2;

  width: 100%;
  object-fit: cover;
  border-radius: 20px;

  @include media-breakpoint-down(xl) {
    border-radius: 16px;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 2;
  }
}

.developTitle {
  grid-row-start: 2;
  color: var(--dark-blue-90);

  @include responsive-padding-top(
    $xxxl: 122px,
    $xxl: 122px,
    $xl: 52px,
    $l: 52px,
    $m: 0,
    $s: 0,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(s) {
    grid-row-start: 3;
  }
}

.descriptionDevelop {
  grid-row-start: 2;
  color: var(--text-black-80);

  @include responsive-padding-top(
    $xxxl: 230px,
    $xxl: 230px,
    $xl: 129px,
    $l: 129px,
    $m: 68px,
    $s: 68px,
    $xs: 16px,
    $xss: 16px
  );

  @include media-breakpoint-down(s) {
    grid-row-start: 4;
  }
}

.skTitle {
  grid-row-start: 3;
  color: var(--dark-blue-90);

  @include responsive-padding-top(
    $xxxl: 130px,
    $xxl: 130px,
    $xl: 130px,
    $l: 72px,
    $m: 72px,
    $s: 0,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 3;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 6;
  }
}

.skDescription {
  grid-row-start: 3;
  color: var(--text-black-80);

  @include responsive-padding-top(
    $xxxl: 202px,
    $xxl: 202px,
    $xl: 202px,
    $l: 150px,
    $m: 150px,
    $s: 68px,
    $xs: 16px,
    $xss: 16px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 3;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 7;
  }
}

.productsTitle {
  grid-row-start: 3;
  color: var(--dark-blue-90);

  @include responsive-padding-top(
    $xxxl: 406px,
    $xxl: 406px,
    $xl: 406px,
    $l: 336px,
    $m: 336px,
    $s: 80px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 4;
  }
  @include media-breakpoint-down(s) {
    grid-row-start: 9;
  }
}

.productsDescription {
  grid-row-start: 3;
  color: var(--text-black-80);

  @include responsive-padding-top(
    $xxxl: 516px,
    $xxl: 516px,
    $xl: 516px,
    $l: 416px,
    $m: 416px,
    $s: 148px,
    $xs: 16px,
    $xss: 16px
  );
  @include media-breakpoint-down(m) {
    grid-row-start: 4;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 10;
  }
}

.productsImg {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;

  @include responsive-margin-top(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 100px,
    $l: 72px,
    $m: 72px,
    $s: 80px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 4;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 8;
  }
}

.serviceImg {
  grid-row-start: 4;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;

  @include responsive-margin-top(
    $xxxl: 100px,
    $xxl: 100px,
    $xl: 72px,
    $l: 72px,
    $m: 72px,
    $s: 80px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 5;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 11;
  }
}

.serviceTitle {
  grid-row-start: 4;
  color: var(--dark-blue-90);

  @include responsive-padding-top(
    $xxxl: 268px,
    $xxl: 268px,
    $xl: 160px,
    $l: 160px,
    $m: 160px,
    $s: 80px,
    $xs: 36px,
    $xss: 36px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 5;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 12;
  }
}

.serviceDescription {
  grid-row-start: 4;
  color: var(--text-black-80);

  @include responsive-padding-top(
    $xxxl: 376px,
    $xxl: 376px,
    $xl: 238px,
    $l: 238px,
    $m: 238px,
    $s: 148px,
    $xs: 16px,
    $xss: 16px
  );

  @include media-breakpoint-down(m) {
    grid-row-start: 5;
  }

  @include media-breakpoint-down(s) {
    grid-row-start: 13;
  }
}
